
import { productsForDatalayer } from '~/utils/productsForDatalayer'

export default {
  name: 'MediSnapCarousel',

  props: {
    autoplay: {
      type: Boolean,
      default: false,
    },

    autoplayInterval: {
      type: Number,
      default: 5000,
    },

    onInViewportEvent: {
      type: String,
      default: 'view_item_list',
    },

    items: {
      type: Array,
      default: () => [],
    },

    slidesPerView: {
      type: Object,
      default: () => ({
        xxs: 1.25,
        xs: 2,
        sm: 2,
        md: 4,
        lg: 4,
        xl: 4,
      }),
    },
  },

  data: () => ({
    slidesGap: 16,
    isStart: true,
    isEnd: false,
    resizeObserver: null,
    autoplayTimeout: null,
  }),

  computed: {
    slidesPerViewCss () {
      return Object.entries(this.slidesPerView).map(([size, count]) => `--slide-${size}: ${count};`).join(' ')
    },
  },

  mounted () {
    this.resizeObserver = new ResizeObserver(this.onScroll)
    this.resizeObserver.observe(this.$refs.wrapper)

    this.onAutoplay()
  },

  beforeDestroy () {
    this.resizeObserver?.disconnect()
    clearTimeout(this.autoplayTimeout)
  },

  methods: {
    onScroll () {
      const el = this.$refs.wrapper

      this.isStart = el.scrollLeft === 0
      this.isEnd = el.scrollLeft + el.offsetWidth >= el.scrollWidth - this.slidesGap
    },

    onAutoplay () {
      clearTimeout(this.autoplayTimeout)

      if (this.autoplay) {
        this.autoplayTimeout = setTimeout(this.onNext, this.autoplayInterval)
      }
    },

    onAutoplayStop () {
      clearTimeout(this.autoplayTimeout)
    },

    onPrev () {
      this.onAutoplay()

      this.$refs.wrapper.scrollBy({
        left: -this.getSlideWidth(),
        behavior: 'smooth',
      })
    },

    onNext () {
      if (this.isEnd) {
        return this.scrollToStart()
      }

      this.onAutoplay()

      this.$refs.wrapper.scrollBy({
        left: this.getSlideWidth(),
        behavior: 'smooth',
      })
    },

    scrollToStart () {
      this.onAutoplay()

      this.$refs.wrapper.scrollTo({
        left: 0,
        behavior: 'smooth',
      })
    },

    getSlideWidth () {
      return (this.$refs.wrapper.getBoundingClientRect().width / this.slidesPerView?.[this.$css.breakpoints.name]) + this.slidesGap || 100
    },

    onInViewport () {
      const { salesManagoId = '' } = this.$store.state?.user?.profile

      let eventData = {}

      switch (this.onInViewportEvent) {
        case 'view_promotion':
          eventData = {
            creative_name: 'Summer Banner',
            creative_slot: 'featured_app_1',
            promotion_id: 'P_12345',
            promotion_name: 'Summer Sale',
          }
          break
        default:
          eventData = {
            item_list_id: this.gtmLabel,
            item_list_name: this.gtmLabel,
          }
          break
      }

      this.$gtm.push({
        event: this.onInViewportEvent,
        ecommerce: {
          ...eventData,
          items: productsForDatalayer(this.items),
          contactId: salesManagoId,
        },
      })
    },
  },
}
